/* You can add global styles to this file, and also import other style files */
// $gutter: 1rem; //for primeflex grid system
// @import "layout/layout.scss";

// /* PrimeNG */
// @import "../../node_modules/primeng/resources/primeng.min.css";
// @import "../../node_modules/primeflex/primeflex.css";
// @import "../../node_modules/primeicons/primeicons.css";

// /* Code Highlight */
// @import "../../node_modules/prismjs/themes/prism-coy.css";


// @import '~bootstrap/dist/css/bootstrap.min.css';
// @import '~bs-stepper/dist/css/bs-stepper.min.css';

/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* PrimeNG */
@import "../../node_modules/primeng/resources/primeng.min.css";
@import "../../node_modules/primeflex/primeflex.scss";
@import "../../node_modules/primeicons/primeicons.css";

/* Layout */
@import "../assets/styles/layout/layout.scss";
@import "../assets/styles/layout/preloading.scss";

:host ::ng-deep .p-toast {
  z-index: 99999;
}

p-tabView {
  ul.p-tabview-nav {
    li {
      a {
        &:focus {
          background-color: rgba(63, 81, 181, 0.12);
          border-radius: 0;
          border-bottom-width: 3px;
        }
      }
    }
  }
}

@media all and (max-width: 991px) {
  .layout-topbar-mobile {
    display: inline-flex !important;
  }

  .layout-topbar-desktop {
    display: none !important;
  }
}

@media all and (min-width: 992px) {
  .layout-topbar-mobile {
    display: none !important;
  }

  .layout-topbar-desktop:not(.p-avatar) {
    display: block !important;
  }
}

.p-paginator-current {
  width: 100% !important;
}

@media (max-width: 640px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

@media (min-width: 641px) and (max-width: 767px) {
  .col-sm-hidden {
    display: none !important;
  }
}

@media (min-width: 641px) and (max-width: 991px) {
  .col-md-hidden {
    display: none !important;
  }
}


.p-datatable-wrapper {

  .sorting_ASC,
  .sorting_DESC,
  .sorting {
    cursor: pointer;
  }

  .p-datatable-thead>tr>th {
    padding: 1rem .25rem;
  }

  .p-datatable-tbody>tr>td {
    word-break: break-all;
    padding: 1rem .25rem;
  }
}

.p-confirm-dialog-message {
  width: 100%;

  .table {
    width: 100%;
  }
}

.validation-form .p-error {
  padding-left: 4px;
  padding-top: 3px;
}

.numberman-page .p-fileupload.p-component {
  float: left !important;
}

.layout-main {
  position: relative;
}

.p-progressbar {
  height: 1rem;
}

.p-element .p-button-icon-only .p-inputnumber-button .p-inputnumber-button-up .p-button p-component {
  border-top-right-radius: 6px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.p-element .p-button-icon-only .p-inputnumber-button .p-inputnumber-button-down .p-button .p-component {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 0px;
}

// .p-button-back {
//   position: absolute !important;
//   top: 163px;
//   right: 70px;
// }
p-fieldset {
  fieldset {
    margin-bottom: 20px !important;
  }
}

.field .p-component-full {
  width: 100%;

  .p-dropdown.p-component {
    width: 100%
  }
}

.billing_method_component {

  input,
  button {
    height: 44px !important;
  }
}

@media (max-width: 640px) {
  .p-datatable-responsive {
    .p-datatable-tbody tr {
      border-bottom: 1px dashed;

      td:first-child {
        padding-top: 2rem;
      }

      td:last-child {
        padding-bottom: 2rem;
      }

      td {
        vertical-align: middle;
      }
    }
  }
}

.page_title {
  padding-bottom: 1rem;
  padding-left: 0.25rem;

  small {
    font-size: 12px;
  }
}

.layout-theme-dark {
  .p-warning-text {
    color: var(--yellow-300)
  }
}

.layout-theme-light {
  .p-warning-text {
    color: var(--yellow-700)
  }
}

.layout-dark{
  #container{
    .highcharts-container {
      svg{
        .highcharts-background{
          fill:#293b51!important;
        }
      }
    } 
   }
   .chart-box{
    .highcharts-background{
      fill:#293b51 !important;
    }
  }
}

.p-blockui-document.p-component-overlay {
  background: var(--text-color);
  opacity: .35
}

.field.StripeElement {
  border: 1px solid var(--text-color-secondary) !important;
  border-radius: 2px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.p-datatable .p-datatable-thead>tr {
  th {
    a {
      color: var(--text-color) !important;
      cursor: pointer;
    }
  }

  th.sorting::after,
  th.sorting_asc::after,
  th.sorting_desc::after {
    font-family: primeicons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 11px;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    margin-left: 4px;
  }

  th.sorting::after {
    content: "\e99e";
  }

  th.sorting_asc::after {
    content: "\e99c";
  }

  th.sorting_desc::after {
    content: "\e99d";
  }
}

p-tabview {
  .p-tabview-nav-container {
    ul {
      li {
        margin-bottom: 1px;
      }
    }
  }
}

.p_spam_button span.p-button-label {
  word-break: break-word !important;
  text-align: left !important;
}

.country_flag {
  width: 20px;
  margin-right: 4px;
}

.page_title {
  padding-top: 2rem;
}

.card-toggle .p-button.p-highlight {
  background: #A5D6A7 !important;
  color: #000 !important;
}

p-inputnumber.p-inputwrapper {
  min-height: 40px !important;
  max-height: 45px !important;
}

// while loader is running
.pace-running .block-ui-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.pace.pace-inactive .splash-screen {
  display: none;
}

@media screen and (min-width: 992px) {
  .layout-container.layout-static .layout-content-wrapper {
    margin-left: 16rem;
  }

  .layout-container.layout-static-inactive .layout-content-wrapper {
    margin-left: 0;
  }

  .layout-container.layout-static .layout-sidebar {
    width: 16rem;
  }

  .layout-container.layout-overlay.layout-overlay-active .layout-sidebar {
    width: 16rem;
  }

}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.layout-sidebar .layout-menu-container {
  direction: rtl;
}

.layout-menu {
  direction: ltr;
}
 
body :has(.layout-light) ~.date_range_panel.p-datepicker {
  .cancel-btn-wrapper{
    background-color: white !important;
    border-top-color: #dee2e6 !important; 
  }
}
// p-calender style
 .date_range.p-calendar {
  width: 100%;
  input{
    padding-right: 0px !important;
  }
}
.date_range_panel.p-datepicker {
  display: flex;
  height:330px;
  table{
    th{
      padding: 0 !important;
    }
    td{
      padding: 0.4rem !important;
    }
  }
  .p-datepicker-header{
    padding: 0px !important;
  }
  td{
    span{
      width: 1.8rem !important;
      height: 1.8rem !important;
      font-size: 13px !important;
    }
  }
  td.p-datepicker-other-month {
    opacity: 0.6;
  }



  .p-datepicker-group-container {
    flex: 9;
    padding-top:45px;
    .p-disabled,
    .p-component:disabled {
      opacity: 1;
    }

    .p-datepicker-other-month {
      .p-disabled,
      .p-component:disabled {
        opacity: 0.38;
      }
    }
  }
}
//custom range wrapper update
.p-datepicker {
  display: flex;
  .p-timepicker{
    padding: 0 !important;
    .p-hour-picker , .p-minute-picker, .p-ampm-picker{
      gap:3px;
      button{
        width: 1rem !important;
        height: 1rem !important;
      }
      span{
        font-size: 1.1rem !important;
      }
    }
  }
}

.calendar-header {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap:4px;

  button {
    font-size: 0.9rem;
    padding: 8px 5px;
    white-space: nowrap;
    width: 85px !important;
    text-align: left;
    box-shadow: none;
    &:focus{
      box-shadow: none !important;
      border: 2px solid #a6b1db;
    }
  }

  button.active {
    background: #121111;
  }
}

.p-datepicker-group-container {
  padding-top: 55px;
}

.custom-range-wrapper .field .p-inputtext {
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  box-shadow: none;
  font-size: 12px;

  }
  .custom-range-wrapper{
    position: absolute;
    padding-inline: 15px;
    top: 0;
    width: 100%;
    left: 0;
    height: 45px;
    display: flex;
    align-items: center;
    .range-heading{
      font-size: 13px;
      font-weight: bold;
      padding-top: 25px;
    }
      .field {
        padding: 4px 15px;
        border: 1px solid #dee2e6;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 5px;
        height: 35px;
        width: 115px;
        font-size: 12px;
        .label{
          font-size: 10px;
          color: #a4a4a4;
        }
      }
  }
  .cancel-btn-wrapper{
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px 10px;
    display: flex;
    align-items: baseline;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid #3e4f62;
    z-index: 9;
    background: inherit;
    border-radius: 0 0 6px 6px;
    button{
      padding: 7px 30px;
    }
  }